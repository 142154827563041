<template>
  <div class="DashBoard">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo titulo="Eventos" @OpenMenuLateral="menuOpen = true"></Topo>
      <div class="container mt-4" style="margin-bottom: 80px">
        <b-row>
          <b-col>
            <div>
              <div class="Eventos" v-if="eventos.length > 0">
                <h4 class="my-2 mb-4">Eventos em Aberto</h4>
                <b-list-group>
                  <b-list-group-item v-for="item in eventos" :key="item.id">
                    <b-row>
                      <b-col cols="8">
                        Protocolo: {{ item.idEvento }}
                        <b-badge>{{ item.Eventos_Status.nomeStatus }}</b-badge
                        ><br />
                        Data do Evento :
                        {{ formataData(item.dataHoraEvento) }}<br />
                        Tipo de Evento: {{ item.Eventos_TipoEvento.descricao
                        }}<br />
                      </b-col>
                      <b-col class="text-right">
                        <router-link :to="`editarEvento/${item.idEvento}/0`">
                          <i class="fa fa-edit mr-2"></i>
                          <span>Editar Evento</span>
                        </router-link>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
                <hr />
              </div>
              <div class="telefone-central text-center my-3">
                <div v-if="veiculos.length == 0" class="alert alert-danger">
                  Nenhum Veículo Encontrado
                </div>
                <div v-else class="alert alert-info">
                  Selecione um dos veiculos para iniciar o Processo de Evento
                </div>
                <b-list-group>
                  <b-list-group-item
                    v-for="item in veiculos"
                    :key="item.id"
                    :variant="`${item.selecionado == true ? 'success' : ''}`"
                  >
                    <b-row @click="selecionaVeiculo(item)">
                      <b-col>
                        {{ item.descricao_modelo }}
                        <b-badge variant="primary">{{ item.placa }}</b-badge>
                        <b-badge>{{ item.situacao }}</b-badge>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
                <b-button
                  v-if="form.idVeiculo"
                  class="mt-4"
                  :style="`background-color:${temaCor.botaoFormularios};`"
                  @click="modalAbertura = true"
                  >Acionar Evento</b-button
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
    <b-modal
      v-model="modalAbertura"
      hide-footer
      centered
      title="Pré Abertura de Evento"
      ><b-form
        @submit.stop.prevent="onSubmit"
        @reset="onReset"
        class="text-left"
      >
        <b-form-group
          id="input-group-2"
          label="Tipo de Evento"
          label-for="input-2"
        >
          <b-form-select
            v-model="form.tipoEvento"
            :options="tiposEventos"
            name="Tipo de Evento"
            v-validate="{ required: true }"
            :state="validateState('Tipo de Evento')"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Envolvimento"
          label-for="input-2"
        >
          <b-form-select
            v-model="form.envolvimento"
            :options="envolvimento"
            name="Envolvimento"
            v-validate="{ required: true }"
            :state="validateState('Envolvimento')"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Data do Evento"
          label-for="input-2"
        >
          <datetime
            name="Data do Evento"
            type="datetime"
            :phrases="{ ok: 'OK', cancel: 'Fechar' }"
            v-model="form.dataEvento"
            :max-datetime="maxDate"
            auto
            v-validate="{ required: true }"
            :state="validateState('Tipo de Evento')"
            :input-class="`form-control ${validateClass('Data do Evento')}`"
          >
            <template slot="button-cancel"> </template>
            <template slot="button-confirm">
              <font-awesome-icon icon="check"></font-awesome-icon> </template
          ></datetime>
        </b-form-group>
        <b-button
          pill
          ref="btnAvancar"
          size="lg"
          type="submit"
          variant="info"
          class="btn btn-block"
          :style="`background-color:${temaCor.botaoFormularios};`"
          >Avançar</b-button
        >
      </b-form>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './TopoInterno';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import pdf from 'vue-pdf';
import moment from 'moment';
import { Settings } from 'luxon';
import locale from './../locale';
import { decryptData } from '../services/decryptData';

Settings.defaultLocale = 'pt-br';

import colors from './../services/colors';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      modalAbertura: false,
      dados: {},
      dadosUser: {},
      carregando: false,
      regulamento: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      temaCor: {},
      veiculos: [],
      eventos: [],
      maxDate: moment().format(),
      tiposEventos: [],
      envolvimento: [{ text: 'Selecione', value: null }, 'Vítima', 'Causador'],
      form: {
        idVeiculo: null,
        tipoEvento: null,
        dataEvento: null,
        envolvimento: null,
      },
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, ConteudoDash, MenuRodape, MenuLateral, pdf },
  methods: {
    onSubmit(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }
        this.carregando = true;
        this.form.idTipoEvento = this.form.tipoEvento.id;
        this.form.from = 'APP';
        service
          .post('Eventos', 'InserirEvento', this.form)
          .then((res) => {
            console.log(res);
            this.carregando = false;
            this.$bvToast.toast('Evento Adicionado com sucesso', {
              title: 'Atenção!',
              solid: true,
              variant: 'success',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
            this.modalAbertura = false;
            var url = `/editarEvento/${res.data.idEvento}/0`;
            this.$router.push(url);
            this.form = {
              idVeiculo: null,
              tipoEvento: null,
              dataEvento: null,
              envolvimento: null,
            };
          })
          .catch((e) => {
            console.log(e);
            this.carregando = false;
            this.$bvToast.toast(e, {
              title: 'Atenção!',
              solid: true,
              variant: 'danger',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 2500,
            });
          });
      });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {
        idVeiculo: null,
        tipoEvento: null,
        dataEvento: null,
        envolvimento: null,
      };
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    selecionaVeiculo(item) {
      this.veiculos.forEach((element) => {
        element.selecionado = false;
      });
      item.selecionado = true;
      this.form.idVeiculo = item.id_veiculo;
      console.log(item);
    },
    formataRegional(id) {
      return id.toString().padStart(4, '0');
    },
    formataId(id) {
      return id.toString().padStart(6, '0');
    },
    formataData(data) {
      if (data) return moment(data).format('DD/MM/YYYY HH:mm');
    },
    formataAno(data) {
      if (data) return moment(data).format('YYYY');
    },
    returnaStyle() {
      var obj = {};
      obj.width = `${this.windowWidth}px`;
      obj.height = `${this.windowHeight - 120}px`;
      if (this.windowWidth > this.windowHeight)
        obj.height = `${this.windowHeight + 300}px`;
      return obj;
    },
    buscaEventos() {
      this.carregando = true;
      this.eventos = [];
      var obj = {};
      obj.idAssociado = this.decoded.id_prospect;
      service.post('Eventos', 'list', obj).then((res) => {
        console.log(res);
        res.data.eventos.forEach((element) => {
          this.eventos.push(element);
        });
        console.log(this.eventos);
      });
    },
    buscaVeiculos() {
      this.carregando = true;
      this.veiculos = [];
      service
        .getAll('Veiculos', {}, 'listEvo')
        .then((res) => {
          console.log(res);
          res.data.veiculos.forEach((element) => {
            element.selecionado = false;
            this.veiculos.push(element);
          });
          this.veiculos.sort(function (a, b) {
            return a.situacao < b.situacao
              ? -1
              : a.situacao > b.situacao
              ? 1
              : 0;
          });

          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          this.erroConexao =
            'Seu aplicativo parece estar offline, verifique sua conexao';
        });
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      this.carregando = true;
      var url = `${model}/list/idEmpresa/${this.form.idEmpresa}`;

      if (model == 'domTipoEvento') url = `${model}/list`;
      service.getAll('g', {}, url).then((res) => {
        res.data = decryptData(res.data);
        this[array].push({ text: 'Selecione', value: null });
        res.data.forEach((element) => {
          this[array].push({
            value: { id: element[campoPK], valor: element[campoDescricao] },
            text: element[campoDescricao],
          });
        });
        console.log(this[array]);
        this.carregando = false;
      });
    },
    verificaToken() {
      this.temaCor = colors.getColorsTema();
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        console.log(this.decoded);
        this.buscaVeiculos();
        this.buscaEventos();
      }
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.verificaToken();
    this.buscaDadosSelects(
      'domTipoEvento',
      'tiposEventos',
      'descricao',
      'idTipoEvento',
    );
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}
.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background-color: #222;
}

.vdatetime-popup__actions__button {
  color: #222;
}
.vdatetime-time-picker__item--selected {
  font-size: 32px;
  background-color: #ccc;
  color: #fff;
}
</style>
